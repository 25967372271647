import styles from '@/styles/superstack/layout/SSheader.module.scss';
import renewalStyles from '@/styles/superstack/layout/SSheader-renewal.module.scss';
import classNames from 'classnames/bind';
import LinkTag from '@/src/component/LinkTag';
import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import useSSProfile from '@/src/utils/hooks/superstack/useSSProfile';
import Gear from '@/asset/superstack/svgs/gear.svg';
import IconBookmarkEmpty from '@/asset/superstack/svgs/icon-bookmark.svg';
import HandBye from '@/asset/superstack/svgs/hand-bye.svg';
import PaperPlane from '@/asset/superstack/svgs/paper-plane.svg';
import IconCultureVote from '@/asset/superstack/svgs/icon-culture-vote.svg';
import CircleChevronDown from '@/asset/superstack/svgs/circle-chevron-down.svg';
import CircleChevronUp from '@/asset/superstack/svgs/circle-chevron-up.svg';
import IconHamburger from '@/asset/superstack/svgs/icon-hamburger.svg';
import IconHamburgerWhite from '@/asset/superstack/svgs/icon-hamburger-white.svg';
import IconPhone from '@/asset/superstack/svgs/icon-phone.svg';

import IconArrowLeft from '@/asset/superstack/svgs/icon-arrow-left.svg';
import ImgLogo from '@/asset/superstack/svgs/logo_wishket_jobs.svg';
import ImgLogoRenewal from '@/asset/superstack/svgs/logo_wishket_jobs_renewal.svg';
import ImgLogoCompany from '@/asset/superstack/svgs/logo_wishket_jobs_company.svg';
import ImgStackMatchLandingM from '@/asset/superstack/svgs/img-stack-match-landing-m.svg';
import LogoWishket from '@/asset/superstack/svgs/logo_wishket.svg';
import LogoYozmIT from '@/asset/superstack/svgs/logo_yozmIT.svg';
import IconEdit from '@/asset/superstack/svgs/icon-edit.svg';
import CompanyLogoDefault from '@/asset/superstack/svgs/company-logo-default.svg';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';
import { CompanyGeneralLinks } from '@/data/superstack/companies/CompanyGeneralLinks';
import useUserCompany from '@/src/utils/hooks/superstack/useUserCompany';
import SSImage from '@/src/component/superstack/base/SSImage';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { api, defaultProfileImgSrc, formDataApi } from '@/src/utils/api';
import IconProfileUpload from '@/asset/superstack/svgs/icon-profile-upload.svg';
import IconProfileDelete from '@/asset/superstack/svgs/icon-profile-delete.svg';
import { SSImageUploadModal } from '@/src/component/superstack/base/Modal';
import { useOutsideClick } from '@/src/utils/hooks/useOutsideClick';
import { useRecoilState } from 'recoil';
import MobileSideBarOpenState from '@/src/store/superstack/MobileSideBarOpenState';
import { handleClickRouteBackMobile, handleServerError, toggleSideBar } from '@/src/utils/ss_utils';
import { handleClickSignUpEvt } from '@/src/utils/tracking_analytics';
import IconArrowRightUp from '@/asset/superstack/svgs/icon-arrow-right-up.svg';
import IconArrowRightUpBlue from '@/asset/superstack/svgs/icon-arrow-right-up-blue.svg';
import { Button } from '@/src/component/superstack/base/Button';
import IconCheck from '@/asset/superstack/svgs/icon-check.svg';
import { SSHeaderSNB } from '@/src/layout/superstack/partials/SSHeaderSNB';
import { CultureLinks } from '@/data/superstack/CultureLinks';
import IconHeaderMenuNew from '@/asset/superstack/svgs/icon-header-menu-new.svg';

const cx = classNames.bind(styles);
const renewalCx = classNames.bind(renewalStyles);

const hideSideBar = (setMobileSideBarOpen) => {
  setMobileSideBarOpen(false);
  document.body.style.overflowY = 'auto';
};

// 공통 요소
const LinkTagLogout = () => {
  return (
    <LinkTag
      href={`https://auth.wishket.com/logout?next=${
        GeneralLinks().find((link) => link.name === 'landing')?.url
      }`}
      isNextPage={false}
      style={{ color: '#212121', textDecorationLine: 'none' }}
    >
      <div className={cx(['selected-dropdown-menu', 'user-logout-dropdown-pc'])}>
        <HandBye className={cx(['header-dropdown-icon', 'header-dropdown-logout'])} />
        <div className={cx(['header-dropdown-menu'])}>로그아웃</div>
      </div>
    </LinkTag>
  );
};

const UserDropDownMenu = ({
  user,
  isDropDownOpen,
  dropdownRef,
  isCompanyHeader = false,
  setIsProfileImageOpen,
  previewUrl,
}) => {
  const userName =
    user.username.length > 11 ? `${user.username.substring(0, 11)}... 님` : user.username;

  if (!isCompanyHeader) {
    return (
      <div
        className={cx(['user-dropdown-menu', isDropDownOpen ? 'is-dropdown-open' : ''])}
        ref={dropdownRef}
      >
        <div className={cx(['user-name-dropdown'])}>
          <a className={cx(['user-name-dropdown-div'])}>
            <SSImage
              className={cx(['header-user-dropdown-image'])}
              src={previewUrl || defaultProfileImgSrc}
              width={32}
              height={32}
            />
          </a>
          <div>{userName}</div>
          <div className={cx(['profile-image-edit-icon'])}>
            <IconEdit
              style={{
                position: 'absolute',
                borderRadius: '10px',
                width: '16px',
                height: '16px',
                top: '28px',
                left: '34px',
              }}
              onClick={() => {
                setIsProfileImageOpen(true);
              }}
            />
          </div>
        </div>
        <MJobSeekerLinks />
        <div className={cx(['header-dropdown-menu-box'])}>
          <LinkTag
            href={
              GeneralLinks().find((link) => link.name === 'ss_profile_mine_contact_info_manage')
                ?.url
            }
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <IconPhone width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>연락처 정보 설정</div>
            </div>
          </LinkTag>
          <LinkTag
            href={'/wishket-member/'}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
            target={'_blank'}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <Gear width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>계정 설정</div>
            </div>
          </LinkTag>
          <LinkTag
            href={GeneralLinks().find((link) => link.name === 'liked_list')?.url}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <IconBookmarkEmpty
                width={20}
                height={20}
                className={cx(['header-dropdown-icon'])}
                style={{ fill: '#fff' }}
              />
              <div className={cx(['header-dropdown-menu'])}>관심 포지션</div>
            </div>
          </LinkTag>
          <LinkTag
            href={GeneralLinks().find((link) => link.name === 'jobs_mine').url}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <PaperPlane width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>지원한 포지션</div>
            </div>
          </LinkTag>
          <LinkTag
            href={CultureLinks().find((url) => url.name === 'user_prefer_culture_self_router').url}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <IconCultureVote width={24} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>나의 컬처코드</div>
            </div>
          </LinkTag>
        </div>
        <LinkTagLogout />
      </div>
    );
  } else {
    return (
      <div
        className={cx(['user-dropdown-menu', isDropDownOpen ? 'is-dropdown-open' : ''])}
        ref={dropdownRef}
      >
        <div className={cx(['user-name-dropdown'])}>
          <a className={cx(['user-name-dropdown-div'])}>
            <SSImage
              className={cx(['header-user-dropdown-image'])}
              src={previewUrl || defaultProfileImgSrc}
              width={32}
              height={32}
            />
          </a>
          <div>{userName}</div>
          <div className={cx(['profile-image-edit-icon'])}>
            <IconEdit
              style={{
                position: 'absolute',
                borderRadius: '10px',
                width: '16px',
                height: '16px',
                top: '28px',
                left: '34px',
              }}
              onClick={() => {
                setIsProfileImageOpen(true);
              }}
            />
          </div>
        </div>
        <MJobSeekerLinks />
        <div className={cx(['header-dropdown-menu-box'])}>
          <LinkTag
            href={
              GeneralLinks().find((link) => link.name === 'ss_profile_mine_contact_info_manage')
                ?.url
            }
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <IconPhone width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>연락처 정보 설정</div>
            </div>
          </LinkTag>
          <LinkTag
            href={'/wishket-member/'}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
            target={'_blank'}
          >
            <div className={cx(['selected-dropdown-menu'])}>
              <Gear width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>계정 설정</div>
            </div>
          </LinkTag>
          <LinkTag
            href={GeneralLinks().find((link) => link.name === 'liked_list')?.url}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu', 'is-company'])}>
              <IconBookmarkEmpty
                width={20}
                height={20}
                className={cx(['header-dropdown-icon'])}
                style={{ fill: '#fff' }}
              />
              <div className={cx(['header-dropdown-menu'])}>관심 포지션</div>
            </div>
          </LinkTag>
          <LinkTag
            href={GeneralLinks().find((link) => link.name === 'jobs_mine').url}
            isNextPage={true}
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            <div className={cx(['selected-dropdown-menu', 'is-company'])}>
              <PaperPlane width={20} height={20} className={cx(['header-dropdown-icon'])} />
              <div className={cx(['header-dropdown-menu'])}>지원한 포지션</div>
            </div>
          </LinkTag>
        </div>
        <LinkTagLogout />
      </div>
    );
  }
};

const HeaderLogo = ({ isBlue = false, isRenewal = false }) => {
  const [isOtherServicesOpen, setIsOtherServicesOpen] = useState(false);
  const toggleOtherService = () => {
    setIsOtherServicesOpen((current) => !current);
  };
  const CircleChevron = isOtherServicesOpen ? CircleChevronUp : CircleChevronDown;
  const menuRef = useOutsideClick(() => {
    setIsOtherServicesOpen(false);
  });
  const stopBubbling = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className={isRenewal ? renewalCx('logo') : cx('logo')} onClick={stopBubbling}>
        <LinkTag
          isNextPage={true}
          href={GeneralLinks().find((link) => link.name === 'landing')?.url}
        >
          <a>{isRenewal ? <ImgLogoRenewal /> : <ImgLogo />}</a>
        </LinkTag>
        <CircleChevron
          width={16}
          height={16}
          fill={isBlue ? '#EEEEEE' : '#9E9E9E'}
          onClick={toggleOtherService}
          style={{ cursor: 'pointer' }}
        />
        <aside
          className={cx([
            'sidebar',
            isOtherServicesOpen ? 'is-block' : '',
            'is-other-service-sidebar',
          ])}
        >
          <div ref={menuRef}>
            <OtherServicesMenu isOtherServicesOpen={isOtherServicesOpen} />
          </div>
        </aside>
      </div>
    </>
  );
};

const HeaderLogoCompany = ({ isRenewal = false }) => {
  return (
    <>
      <div
        className={isRenewal ? renewalCx('logo') : cx('logo')}
        style={{ position: 'relative', top: '1px', left: '1px' }}
      >
        <LinkTag
          isNextPage={true}
          href={CompanyGeneralLinks().find((link) => link.name === 'company_landing')?.url}
        >
          <a>{isRenewal ? <ImgLogoRenewal /> : <ImgLogoCompany />}</a>
        </LinkTag>
      </div>
    </>
  );
};

const MJobSeekerLinks = ({ checkCurrent = false }) => {
  const router = useRouter();
  const iconCheck = checkCurrent ? <IconCheck width={14} height={10} fill={'#006DFF'} /> : null;
  return (
    <div className={cx(['job-seeker-links-mobile', 'last-menu'])}>
      <LinkTag isNextPage={true} href={GeneralLinks().find((link) => link.name === 'landing')?.url}>
        <div className={cx('link')}>
          {router.pathname === '/superstack' && iconCheck}
          위시켓 잡스 홈
        </div>
      </LinkTag>
      <LinkTag
        isNextPage={true}
        href={GeneralLinks().find((link) => link.name === 'displaying_list')?.url}
      >
        <div className={cx('link')}>
          {(router.pathname === '/superstack/jobs/displaying' ||
            router.pathname === '/superstack/jobs/[id]') &&
            iconCheck}
          포지션 찾기
        </div>
      </LinkTag>
      <LinkTag
        isNextPage={true}
        href={GeneralLinks().find((link) => link.name === 'stacks_companies')?.url}
      >
        <div className={cx('link')}>
          {router.pathname && router.pathname.includes('/superstack/stacks/companies') && iconCheck}
          기술 스택
        </div>
      </LinkTag>
      <LinkTag
        isNextPage={true}
        href={GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url}
      >
        <div className={cx('link')}>
          {router.pathname &&
            router.pathname.includes('/superstack/cultures/companies') &&
            iconCheck}
          컬처코드
          <span style={{ position: 'relative', right: '8px', top: '-8px' }}>
            <IconHeaderMenuNew />
          </span>
        </div>
      </LinkTag>
      <LinkTag
        isNextPage={true}
        href={GeneralLinks().find((link) => link.name === 'ss_profile_mine_home')?.url}
      >
        <div className={cx('link')}>
          {router.pathname && router.pathname.includes('/superstack/profiles/mine/') && iconCheck}
          프로필 관리
        </div>
      </LinkTag>
    </div>
  );
};

const LinkToggleService = ({ name, url, sx, isBlue = false }) => {
  return (
    <LinkTag href={url} isNextPage={true}>
      <button
        className={cx(['link-toggle-service', 'selected-service', isBlue ? 'is-blue' : ''])}
        style={sx}
      >
        <p>{name}</p>
        {isBlue ? (
          <IconArrowRightUpBlue width={9} height={14} />
        ) : (
          <IconArrowRightUp width={9} height={14} />
        )}
      </button>
    </LinkTag>
  );
};

const HeaderMenuLink = ({
  href,
  curPath,
  isNextPage,
  name,
  target = '_self',
  isRenewal = false,
  ...props
}) => {
  const cx_ = isRenewal ? renewalCx : cx;
  const isCurPath =
    curPath.includes(href) ||
    (href.includes('/superstack/profiles/mine/') && curPath.includes('/superstack/profiles/mine/'));
  return (
    <LinkTag href={href} isNextPage={isNextPage} target={target} {...props}>
      <span className={cx_(['header-menu-link', isCurPath ? 'active' : ''])}>{name}</span>
      {name === '컬처코드' && (
        <span style={{ position: 'relative', right: '-2px', top: '-7px' }}>
          <IconHeaderMenuNew />
        </span>
      )}
    </LinkTag>
  );
};

const OtherServicesMenu = ({ isOtherServicesOpen }) => {
  return (
    <div className={cx(['other-services', isOtherServicesOpen ? 'is-other-service-open' : ''])}>
      <div className={cx(['other-services-text'])}>다른 서비스</div>
      <a
        href={GeneralLinks().find((link) => link.name === '위시켓').url}
        style={{ textDecoration: 'none' }}
      >
        <div className={cx(['other-services-wrapper', 'first-service'])}>
          <div className={cx(['other-services-logo'])}>
            <LogoWishket />
          </div>
          <div>
            <a className={cx(['other-services-name'])}>위시켓</a>
            <br />
            <a className={cx(['other-services-info'])}>아웃소싱 플랫폼</a>
          </div>
        </div>
      </a>
      <LinkTag href={'/magazine/'} isNextPage={false}>
        <div className={cx(['other-services-wrapper'])}>
          <div className={cx(['other-services-logo'])}>
            <LogoYozmIT />
          </div>
          <div>
            <a className={cx(['other-services-name'])}>요즘IT</a>
            <br />
            <a className={cx(['other-services-info'])}>성장을 위한 IT 매거진</a>
          </div>
        </div>
      </LinkTag>
    </div>
  );
};
// 공통 요소 끝

// 비로그인 헤더
const signNextUrl = (isCompanyLanding, router) => {
  return isCompanyLanding
    ? CompanyGeneralLinks().find((link) => link.name === 'create_new_company')?.url
    : router.asPath;
};

const handleClickLogin = (isCompanyLanding, router) => {
  const signInUrl = `${
    GeneralLinks().find((link) => link.name === 'wishket_signin')?.url
  }?next=${signNextUrl(isCompanyLanding, router)}`;
  router.push(signInUrl, signInUrl);
};

const handleClickSignUp = (isCompanyLanding, router) => {
  handleClickSignUpEvt();
  const signUpUrl = `${
    GeneralLinks().find((link) => link.name === 'wishket_signup')?.url
  }?next=${signNextUrl(isCompanyLanding, router)}`;
  router.push(signUpUrl, signUpUrl);
};

const HeaderRightNotLogin = ({
  mobileSideBarOpen,
  setMobileSideBarOpen,
  isCompanyLanding = false,
}) => {
  const router = useRouter();
  const signInBtnName = isCompanyLanding ? '기업회원 로그인' : '로그인';
  const signUpBtnName = isCompanyLanding ? '기업회원 회원가입' : '회원가입';
  const LinkToggleService_ = isCompanyLanding ? (
    <LinkToggleService
      name={'위시켓 잡스'}
      url={GeneralLinks().find((link) => link.name === 'displaying_list')?.url}
      isBlue={true}
    />
  ) : (
    <LinkToggleService
      name={'기업 서비스'}
      url={CompanyGeneralLinks().find((link) => link.name === 'company_main_router')?.url}
      isBlue={true}
    />
  );
  return (
    <>
      <div className={renewalCx(['header-right', 'pc'])}>
        <div className={renewalCx(['header-right-menu'])}>
          <div className={renewalCx(['button-wrapper'])}>
            <button
              className={renewalCx([
                'sign-in-button',
                isCompanyLanding ? 'is-company-landing' : '',
              ])}
              onClick={() => {
                handleClickLogin(isCompanyLanding, router);
              }}
            >
              {signInBtnName}
            </button>
            <button
              className={renewalCx([
                'sign-up-button',
                isCompanyLanding ? 'is-company-landing' : '',
              ])}
              onClick={() => {
                handleClickSignUp(isCompanyLanding, router);
              }}
            >
              {signUpBtnName}
            </button>
            <span className={renewalCx('header-right-sep')} />
            {LinkToggleService_}
          </div>
        </div>
      </div>
      <div className={renewalCx(['header-right', 'mobile', 'mobile-hamburger-wrapper'])}>
        <span className={renewalCx('sign-up-button-external')}>
          <Button
            mode={'filled'}
            size={'sm'}
            onClick={() => {
              handleClickSignUp(isCompanyLanding, router);
            }}
          >
            <a className={renewalCx('link-text')}>회원가입</a>
          </Button>
        </span>
        <IconHamburger
          width={24}
          height={24}
          className={renewalCx(['mobile'])}
          onClick={() => {
            toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
          }}
        />
      </div>
    </>
  );
};

const MobileSidebarNotLogin = ({ isBlock, isCompanyLanding = false }) => {
  const router = useRouter();
  const signInBtnName = isCompanyLanding ? '기업회원 로그인' : '로그인';
  const signUpBtnName = isCompanyLanding ? '기업회원 회원가입' : '회원가입';
  return (
    <>
      <div className={renewalCx(['sidebar-content', 'mobile', isBlock ? 'isBlock' : 'isNotBlock'])}>
        <div className={renewalCx(['sidebar-content-menu'])}>
          <div className={renewalCx('mobile-sidebar-top')}>
            <ImgStackMatchLandingM />
            <p className={renewalCx('mobile-sidebar-top-text')}>
              기술 스택 매칭으로 <br />
              성과내는 개발자를 채용하세요!
            </p>
          </div>
          <div className={renewalCx(['button-wrapper'])}>
            <a
              href={'#'}
              onClick={() => {
                handleClickLogin(isCompanyLanding, router);
              }}
              className={renewalCx([
                'sign-in-button',
                isCompanyLanding ? 'is-company-landing' : '',
              ])}
            >
              {signInBtnName}
            </a>
            <a
              href={'#'}
              onClick={() => {
                handleClickSignUp(isCompanyLanding, router);
              }}
              className={renewalCx([
                'sign-up-button',
                isCompanyLanding ? 'is-company-landing' : '',
              ])}
            >
              {signUpBtnName}
            </a>
          </div>
        </div>
        <MJobSeekerLinks checkCurrent={true} />
      </div>
    </>
  );
};

const IsNotLoginHeaderLanding = () => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const router = useRouter();
  const url = [
    GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
    GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url,
    GeneralLinks().find((link) => link.name === 'stacks_companies')?.url,
    GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url,
  ];
  return (
    <section className={renewalCx(['ssheader'])}>
      <div className={renewalCx(['pc'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogo isRenewal={true} />
            <div className={renewalCx(['header-left-menu'])}>
              <HeaderMenuLink
                href={url[0]}
                curPath={router.asPath}
                isNextPage={true}
                name={'포지션 찾기'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[2]}
                curPath={router.asPath}
                isNextPage={true}
                name={'기술 스택'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[3]}
                curPath={router.asPath}
                isNextPage={true}
                name={'컬처코드'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[1]}
                curPath={router.asPath}
                isNextPage={false}
                name={'이용 방법'}
                target={'_blank'}
                isRenewal={true}
              />
            </div>
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={false}
          />
        </div>
      </div>
      <div className={renewalCx(['mobile'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogo isRenewal={true} />
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={false}
          />
        </div>
        <SSHeaderSNB />
        <aside className={renewalCx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <MobileSidebarNotLogin isBlock={mobileSideBarOpen} />
          </div>
        </aside>
      </div>
    </section>
  );
};

const IsNotLoginHeaderLandingCompany = () => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const router = useRouter();
  const url = [
    CompanyGeneralLinks().find((link) => link.name === 'create_new_company')?.url,
    GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url,
  ];
  return (
    <section className={renewalCx('ssheader')}>
      <div className={renewalCx(['pc'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogoCompany isRenewal={true} />
            <div className={renewalCx(['header-left-menu'])}>
              <HeaderMenuLink
                href={url[0]}
                curPath={router.asPath}
                isNextPage={true}
                name={'기업 회원가입'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[1]}
                curPath={router.asPath}
                isNextPage={false}
                name={'이용 방법'}
                target={'_blank'}
                isRenewal={true}
              />
            </div>
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={true}
          />
        </div>
      </div>
      <div className={renewalCx(['mobile'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogoCompany isRenewal={true} />
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={true}
          />
        </div>
        <aside className={renewalCx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <MobileSidebarNotLogin isBlock={mobileSideBarOpen} isCompanyLanding={true} />
          </div>
        </aside>
      </div>
    </section>
  );
};

const IsNotLoginHeader = ({ router }) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const url = [
    GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
    GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url,
    GeneralLinks().find((link) => link.name === 'stacks_companies')?.url,
    GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url,
  ];
  return (
    <section className={renewalCx('ssheader')}>
      <div className={renewalCx(['pc'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogo isRenewal={true} />
            <div className={renewalCx(['header-left-menu'])}>
              <HeaderMenuLink
                href={url[0]}
                curPath={router.asPath}
                isNextPage={true}
                name={'포지션 찾기'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[2]}
                curPath={router.asPath}
                isNextPage={true}
                name={'기술 스택'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[3]}
                curPath={router.asPath}
                isNextPage={true}
                name={'컬처코드'}
                isRenewal={true}
              />
              <HeaderMenuLink
                href={url[1]}
                curPath={router.asPath}
                isNextPage={false}
                name={'이용 방법'}
                target={'_blank'}
                isRenewal={true}
              />
            </div>
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={false}
          />
        </div>
      </div>
      <div className={renewalCx(['mobile'])}>
        <div className={renewalCx(['ssheader-container'])}>
          <div className={renewalCx(['header-left'])}>
            <HeaderLogo isRenewal={true} />
          </div>
          <HeaderRightNotLogin
            mobileSideBarOpen={mobileSideBarOpen}
            setMobileSideBarOpen={setMobileSideBarOpen}
            isCompanyLanding={false}
          />
        </div>
        <aside className={renewalCx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <MobileSidebarNotLogin isBlock={mobileSideBarOpen} />
          </div>
        </aside>
      </div>
    </section>
  );
};
// 비로그인 헤더 끝

// 로그인 헤더
const SideBarIsLogin = ({ user, isBlock, isCompany = false, ...props }) => {
  const dropdownRef = useRef();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const clickDropDown = () => {
    setIsDropDownOpen((current) => !current);
  };
  const handleClickModal = () => {
    if (isDropDownOpen && dropdownRef.current) {
      setIsDropDownOpen(false);
    }
  };
  const [isProfileImageOpen, setIsProfileImageOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('click', handleClickModal);
    return () => {
      window.removeEventListener('click', handleClickModal);
    };
  }, [isDropDownOpen]);

  const [previewUrl, setPreviewUrl] = useState(user.profile_image);

  useEffect(() => {
    setPreviewUrl(user.profile_image);
  }, [user.profile_image]);

  return (
    <>
      <div className={cx(['header-right', 'pc'])}>
        <div className={cx(['header-user-image-box'])} onClick={clickDropDown}>
          <div className={cx(['header-user-image'])}>
            <SSImage src={previewUrl || defaultProfileImgSrc} width={32} height={32} />
          </div>
          <UserDropDownMenu
            user={user}
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            dropdownRef={dropdownRef}
            isCompanyHeader={isCompany}
            setIsProfileImageOpen={setIsProfileImageOpen}
            previewUrl={previewUrl}
          />
        </div>
        <span className={cx('header-right-sep')} />
        <div className={cx(['header-right-menu'])}>
          {isCompany && (
            <LinkToggleService
              name={'위시켓 잡스'}
              url={GeneralLinks().find((link) => link.name === 'displaying_list')?.url}
            />
          )}
          {!isCompany && (
            <LinkToggleService
              name={'기업 서비스'}
              url={CompanyGeneralLinks().find((link) => link.name === 'company_main_router')?.url}
            />
          )}
        </div>

        {user.is_active && (
          <ProfileImageSettingModal
            user={user}
            isProfileImageOpen={isProfileImageOpen}
            setIsProfileImageOpen={setIsProfileImageOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            mutate={props.mutate ? props.mutate : null}
          />
        )}
        <div
          className={cx(['user-logout-dropdown', 'selected-dropdown-menu', 'logout-icon-order'])}
        >
          <LinkTagLogout />
        </div>
      </div>
      <div className={cx(['header-right', 'mobile', isBlock ? 'isBlock' : 'isNotBlock'])}>
        <div className={cx(['header-user-image-box'])} onClick={clickDropDown}>
          <UserDropDownMenu
            user={user}
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            dropdownRef={dropdownRef}
            isCompanyHeader={isCompany}
            setIsProfileImageOpen={setIsProfileImageOpen}
            previewUrl={previewUrl}
          />
        </div>
        <div
          className={cx(['user-logout-dropdown', 'selected-dropdown-menu', 'logout-icon-order'])}
        >
          <LinkTagLogout />
        </div>
      </div>
    </>
  );
};

const IsNotApprovedCompanyHeader = ({ user }) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const router = useRouter();
  const url = [
    CompanyGeneralLinks().find((link) => link.name === 'create_new_company')?.url,
    GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url,
  ];
  return (
    <section className={cx(['SSheader', 'header-is-gray'])}>
      <div className={cx(['SSheader-container'])}>
        <div className={cx(['header-left'])}>
          <HeaderLogoCompany />
          <div className={cx(['header-left-menu'])}>
            <div className={cx(['header-link-box'])}>
              <HeaderMenuLink
                href={url[0]}
                curPath={router.asPath}
                isNextPage={true}
                name={'기업 회원가입'}
              />
            </div>
            <div className={cx(['header-link-box'])}>
              <HeaderMenuLink
                href={url[1]}
                curPath={router.asPath}
                isNextPage={false}
                name={'이용 방법'}
                target={'_blank'}
              />
            </div>
          </div>
        </div>
        <aside className={cx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <SideBarIsLogin user={user} isBlock={mobileSideBarOpen} isCompany={true} />
          </div>
        </aside>
        <IconHamburgerWhite
          width={24}
          height={24}
          className={cx(['mobile-icon-hamburger', 'mobile-icon-hamburger-right'])}
          onClick={() => {
            toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
          }}
        />
      </div>
    </section>
  );
};

const IsLoginJobSeekerLanding = ({ user, isBlue = false, isCompany = false }) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const router = useRouter();
  const url = [
    GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
    GeneralLinks().find((link) => link.name === 'ss_profile_mine_home')?.url,
    GeneralLinks().find((link) => link.name === 'stacks_companies')?.url,
    GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url,
    GeneralLinks().find((link) => link.name === 'jobs_mine')?.url,
  ];

  return (
    <>
      <section
        className={cx([`SSheader${isBlue ? '-blue' : ''}`, isCompany ? 'header-is-gray' : ''])}
      >
        <div className={cx(['SSheader-container'])}>
          <div className={cx(['header-left'])}>
            <HeaderLogo isBlue={isBlue} />
            <div className={cx(['header-left-menu'])}>
              <HeaderMenuLink
                href={url[0]}
                curPath={router.asPath}
                isNextPage={true}
                name={'포지션 찾기'}
              />
              <HeaderMenuLink
                href={url[2]}
                curPath={router.asPath}
                isNextPage={true}
                name={'기술 스택'}
              />
              <HeaderMenuLink
                href={url[3]}
                curPath={router.asPath}
                isNextPage={true}
                name={'컬처코드'}
              />
              <HeaderMenuLink
                href={url[1]}
                curPath={router.asPath}
                isNextPage={true}
                name={'프로필 관리'}
              />
              <HeaderMenuLink
                href={url[4]}
                curPath={router.asPath}
                isNextPage={true}
                name={'지원한 포지션'}
              />
            </div>
          </div>
          <aside className={cx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
            <div ref={sideBarRef}>
              <SideBarIsLogin
                user={user}
                isBlock={mobileSideBarOpen}
                isCompany={isCompany}
                onClick={() => {
                  toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
                }}
              />
            </div>
          </aside>
          <IconHamburgerWhite
            width={24}
            height={24}
            className={cx(['mobile-icon-hamburger', 'mobile-icon-hamburger-right'])}
            onClick={() => {
              toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
            }}
          />
        </div>
      </section>
      <SSHeaderSNB isLogin={true} />
    </>
  );
};

const IsLoginJobSeeker = ({ user, pageTitle, referer, router }) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const { mutate } = useSSProfile();
  const url = [
    GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
    GeneralLinks().find((link) => link.name === 'ss_profile_mine_home')?.url,
    GeneralLinks().find((link) => link.name === 'stacks_companies')?.url,
    GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url,
    GeneralLinks().find((link) => link.name === 'jobs_mine')?.url,
  ];
  return (
    <section className={cx(['SSheader', 'header-not-landing'])}>
      <div className={cx(['SSheader-container', 'container-not-landing'])}>
        <div
          className={cx('icon-arrow')}
          onClick={() => {
            handleClickRouteBackMobile(router, referer);
          }}
        >
          <IconArrowLeft width={8} height={16} fill="#000000" />
        </div>
        <div className={cx('title')}>{pageTitle}</div>
        <div className={cx(['header-left'])}>
          <div className={cx(['not-landing-logo'])}>
            <HeaderLogo />
          </div>
          <div className={cx(['header-left-menu'])}>
            <HeaderMenuLink
              href={url[0]}
              curPath={router.asPath}
              isNextPage={true}
              name={'포지션 찾기'}
            />
            <HeaderMenuLink
              href={url[2]}
              curPath={router.asPath}
              isNextPage={true}
              name={'기술 스택'}
            />
            <HeaderMenuLink
              href={url[3]}
              curPath={router.asPath}
              isNextPage={true}
              name={'컬처코드'}
            />
            <HeaderMenuLink
              href={url[1]}
              curPath={router.asPath}
              isNextPage={true}
              name={'프로필 관리'}
            />
            <HeaderMenuLink
              href={url[4]}
              curPath={router.asPath}
              isNextPage={true}
              name={'지원한 포지션'}
            />
          </div>
        </div>
        <aside className={cx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <SideBarIsLogin mutate={mutate} user={user} isBlock={mobileSideBarOpen} />
          </div>
        </aside>
        <IconHamburger
          width={24}
          height={24}
          className={cx(['mobile-icon-hamburger'])}
          onClick={() => {
            toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
          }}
        />
      </div>
    </section>
  );
};

const IsApprovedCompany = ({ user, company, pageTitle, referer, router }) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useRecoilState(MobileSideBarOpenState);
  const sideBarRef = useOutsideClick(() => {
    hideSideBar(setMobileSideBarOpen);
  });
  const url = [
    CompanyGeneralLinks(company.id).find((link) => link.name === 'job_register_new_job')?.url,
    CompanyGeneralLinks(company.id).find((link) => link.name === 'company_jobs')?.url,
    CompanyGeneralLinks(company.id).find((link) => link.name === 'company_manage_router')?.url,
    CompanyGeneralLinks(company.id).find((link) => link.name === 'company_admins')?.url,
    CompanyGeneralLinks(company.id).find((link) => link.name === 'companies_stacks')?.url,
  ];
  const companyName = company.name.length > 7 ? `${company.name.substring(0, 7)}...` : company.name;
  return (
    <section className={cx(['SSheader', 'header-is-company', 'header-not-landing'])}>
      <div className={cx(['SSheader-container', 'container-not-landing'])}>
        <div
          className={cx('icon-arrow')}
          onClick={() => {
            handleClickRouteBackMobile(router, referer);
          }}
        >
          <IconArrowLeft width={8} height={16} fill="#000000" />
        </div>
        <div className={cx('title')}>{pageTitle}</div>
        <div className={cx(['header-left'])}>
          <LinkTag href={url[4]} isNextPage={true}>
            <div className={cx(['header-company-info'])}>
              <div className={cx(['header-company-logo', 'header-is-company'])}>
                {company.logo ? (
                  <SSImage src={company.logo} width={32} height={32} objectFit={'contain'} />
                ) : (
                  <CompanyLogoDefault width={32} height={32} />
                )}
              </div>
              <div className={cx(['header-company-name'])}>{companyName}</div>
            </div>
          </LinkTag>
          <div className={cx(['header-left-menu'])}>
            <HeaderMenuLink
              href={url[1]}
              curPath={router.asPath}
              isNextPage={true}
              name={'공고 관리'}
            />
            <HeaderMenuLink
              href={url[0]}
              curPath={router.asPath}
              isNextPage={true}
              name={'공고 등록'}
            />
            <HeaderMenuLink
              href={url[4]}
              curPath={router.asPath}
              isNextPage={true}
              name={'기술 스택'}
            />
            <HeaderMenuLink
              href={url[2]}
              curPath={router.asPath}
              isNextPage={true}
              name={'회사 정보'}
            />
            <HeaderMenuLink
              href={url[3]}
              curPath={router.asPath}
              isNextPage={true}
              name={'관리자 설정'}
            />
          </div>
        </div>
        <aside className={cx(['sidebar', mobileSideBarOpen ? 'is-block' : ''])}>
          <div ref={sideBarRef}>
            <SideBarIsLogin
              isCompany={true}
              user={user}
              company={company}
              isBlock={mobileSideBarOpen}
            />
          </div>
        </aside>
        <IconHamburger
          width={24}
          height={24}
          className={cx(['mobile-icon-hamburger'])}
          onClick={() => {
            toggleSideBar(mobileSideBarOpen, setMobileSideBarOpen);
          }}
        />
      </div>
    </section>
  );
};
// 로그인 헤더 끝

const SelectedHeader = ({ pageTitle, referer, user }) => {
  const { data: { data: company = {} } = {} } = useUserCompany(user.username);
  const router = useRouter();
  const isCompanyHeader = () => {
    return router.asPath.includes('superstack/companies') && !router.asPath.includes('home/');
  };
  const hasCompanyApproved = (company) => {
    return company?.id > 0;
  };

  if (!user?.username) {
    return <IsNotLoginHeader router={router} />;
  }
  if (isCompanyHeader()) {
    return hasCompanyApproved(company) ? (
      <IsApprovedCompany
        user={user}
        company={company}
        pageTitle={pageTitle}
        referer={referer}
        router={router}
      />
    ) : (
      <IsNotApprovedCompanyHeader user={user} />
    );
  } else {
    return <IsLoginJobSeeker user={user} pageTitle={pageTitle} referer={referer} router={router} />;
  }
};

function SSHeader({ pageTitle, referer, user }) {
  return (
    <header>
      <SelectedHeader pageTitle={pageTitle} referer={referer} user={user} />
    </header>
  );
}

function SSHeaderLanding({ user }) {
  return (
    <header>
      {user?.username ? (
        <IsLoginJobSeekerLanding user={user} isBlue={true} />
      ) : (
        <IsNotLoginHeaderLanding />
      )}
    </header>
  );
}

function SSHeaderLandingCompany({ user }) {
  return (
    <header>
      {user?.username ? <SelectedHeader user={user} /> : <IsNotLoginHeaderLandingCompany />}
    </header>
  );
}

const ProfileImageSettingModal = ({
  user,
  isProfileImageOpen,
  setIsProfileImageOpen,
  previewUrl,
  setPreviewUrl,
  ...props
}) => {
  const cx = classNames.bind(styles);
  const url = ApiUrls(null, null, user.username).find((url) => url.name === 'ss_profile_image').url;
  const inputRef = useRef();
  const [profileImage, setProfileImage] = useState(null);
  const [modalPreviewUrl, setModalPreviewUrl] = useState(previewUrl || '');

  useEffect(() => {
    setModalPreviewUrl(previewUrl);
  }, [previewUrl]);

  const postProfileImage = async () => {
    const form = new FormData();
    form.append('image', profileImage);
    try {
      await formDataApi.post(url, form);
    } catch (error) {
      handleServerError(error);
    }
  };

  const deleteProfileImage = () => {
    setProfileImage(null);
    setModalPreviewUrl('');
  };

  const submitHandler = async () => {
    if (profileImage instanceof File) {
      await postProfileImage(profileImage);
    } else if (modalPreviewUrl === '') {
      await api.delete(url);
    }
    props.mutate ? await props.mutate(user.profile_image) : null;
    await setPreviewUrl(modalPreviewUrl);
  };

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleChangeImageUpload = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);

    let reader = new FileReader();
    reader.onload = () => {
      setModalPreviewUrl(reader.result || '');
    };
    reader.readAsDataURL(file);
  };

  const bodyContent = (
    <div className={cx(['profile-img-modal-wrapper'])}>
      <div className={cx(['profile-img-modal-box', 'profile-img-modal-box-mobile'])}>
        <div className={cx(['profile-img-text'])}>
          나를 알릴 수 있는 프로필 사진을 등록해 주세요.
        </div>
        <form>
          <div className={cx(['profile-img-form'])}>
            <div className={cx(['profile-img-box'])}>
              <SSImage
                className={cx(['profile-img'])}
                src={modalPreviewUrl || defaultProfileImgSrc}
                width={90}
                height={90}
              />
            </div>
            <div className={cx(['btn-box-profile-img'])}>
              <div className={cx(['btn-profile-img'])} onClick={handleClick}>
                <div style={{ paddingTop: '4px' }}>
                  <IconProfileUpload />
                </div>
                <div>사진 올리기</div>
              </div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={inputRef}
                onChange={handleChangeImageUpload}
                style={{ display: 'none' }}
              />
              <div className={cx(['btn-profile-img'])} onClick={deleteProfileImage}>
                <div style={{ paddingTop: '4px' }}>
                  <IconProfileDelete />
                </div>
                <div>사진 지우기</div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={cx(['profile-help-text-box'])}>
        <p className={cx(['profile-help-text'])}>
          · 프로필 사진은 위시켓 잡스 서비스에 표시됩니다.
        </p>
        <p className={cx(['profile-help-text'])}>
          · .jpg, .jpeg, .png, .gif 파일만 업로드 가능합니다.
        </p>
        <p className={cx(['profile-help-text'])}>· 최적 이미지 사이즈는 480 x 480px입니다.</p>
      </div>
    </div>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SSImageUploadModal
        title={'프로필 사진 등록'}
        isOpen={isProfileImageOpen}
        btnSubmitName={'등록 완료'}
        btnCloseName={'닫기'}
        onSubmit={submitHandler}
        onClose={() => {
          setIsProfileImageOpen(false);
        }}
        afterClose={() => {
          setModalPreviewUrl(previewUrl);
        }}
        modalType={'profile'}
        bodyContent={bodyContent}
        closeIcon={true}
      />
    </div>
  );
};

export { SSHeader, SSHeaderLanding, SSHeaderLandingCompany, ProfileImageSettingModal };
