export const ProfileManageLinks = () => {
  return [
    { id: 1, name: 'ss_profile_mine_skills_form', url: '/superstack/profiles/mine/skills-form/' },
    {
      id: 2,
      name: 'ss_profile_mine_skills_stacks_form',
      url: '/superstack/profiles/mine/skills-stacks-form/',
    },
    {
      id: 3,
      name: 'ss_profile_mine_employment_update',
      url: '/superstack/profiles/mine/employment/update/',
    },
    {
      id: 4,
      name: 'ss_profile_default_form',
      url: '/superstack/profiles/mine/default-profile-form/',
    },
  ];
};
