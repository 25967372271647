export const CultureLinks = (companyId = null, username = null) => {
  return [
    {
      id: 1,
      name: 'culture_profile_employment_form',
      url: `/superstack/cultures/companies/${companyId}/${username}/employment-profile-form/`,
    },
    {
      id: 2,
      name: 'culture_survey_data',
      url: `/superstack/culture_survey?company_id=${companyId}`,
    },
    {
      id: 3,
      name: 'user_culture_reviews',
      url: `/superstack/cultures/companies/${companyId}/${username}/reviews/`,
    },
    {
      id: 12,
      name: 'user_culture_review_router',
      url: `/superstack/cultures/companies/${companyId}/router/`,
    },
    {
      id: 4,
      name: 'user_culture_review_form',
      url: `/superstack/cultures/companies/${companyId}/${username}/`,
    },
    {
      id: 15,
      name: 'user_culture_review_latest',
      url: `/superstack/cultures/companies/${companyId}/${username}/reviews/latest/`,
    },
    {
      id: 5,
      name: 'user_culture_review_submitted',
      url: `/superstack/cultures/companies/${companyId}/${username}/submitted/`,
    },
    {
      id: 13,
      name: 'user_prefer_culture_self_router',
      url: '/superstack/cultures/self/router/',
    },
    {
      id: 6,
      name: 'user_prefer_culture_self',
      url: `/superstack/cultures/${username}/self/`,
    },
    {
      id: 7,
      name: 'user_prefer_culture_submitted',
      url: `/superstack/cultures/${username}/submitted/`,
    },
    {
      id: 8,
      name: 'company_culture_review_result',
      url: `/superstack/cultures/companies/${companyId}/`,
    },
    {
      id: 11,
      name: 'company_culture_review_results',
      url: '/superstack/cultures/companies/',
    },
    {
      id: 9,
      name: 'culture_survey_data_v1',
      url: '/superstack/cultures/v1/culture_survey',
    },
    {
      id: 10,
      name: 'culture_recommend_companies',
      url: `/superstack/cultures/v1/recs/${username}/companies/`,
    },
    {
      id: 14,
      name: 'user_culture_review_watings',
      url: `/superstack/cultures/companies/${companyId}/${username}/watings/`,
    },
    {
      id: 17,
      name: 'company_culture_review_result_exist',
      url: `/superstack/cultures/companies/${companyId}/exist-check/`,
    },
    {
      id: 18,
      name: 'user_culture_review_exist',
      url: `/superstack/cultures/u/${username}/exist-check/`,
    },
  ];
};
